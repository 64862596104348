import { ArrowBack } from '@mui/icons-material';
import { Button, IconButton, LinearProgress, Stack } from '@mui/material';
import { useFormik } from 'formik';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'translations/hook';
import * as yup from 'yup';

import { askForContactRequest } from 'business/lp-platform/contact-us/services/contact-request';
import LpPlatformRoutes from 'business/lp-platform/router/routes';
import { useAppContext } from 'business/providers/app-provider';
import { router } from 'business/router/services';
import { UploadedFile } from 'business/shared/components/uploaded-file/uploaded-file';
import ConnectedPage from 'business/user/helpers/connected-page';
import { getAuthErrorContextFromAuthProviderError } from 'business/user/services/errors';
import { message, name, simpleSelect } from 'business/user/validations/string';
import { useGetUploadUrlLazyQuery } from 'generated/graphql';
import { uploadObjectAsync } from 'technical/file-management';
import { MAX_ATTACHMENTS_FILE_SIZE } from 'technical/file-management/constant';
import { IFile } from 'technical/file-management/types';
import { formikErrorMessage } from 'technical/form';
import { generateUniqueFilePath } from 'technical/string/generate-unique-file-path';
import { CheckBox } from 'ui/check-box';
import { FileInput } from 'ui/form';
import { DropDown } from 'ui/form/drop-down/drop-down';
import Loader from 'ui/loader';
import BasicModal from 'ui/modal/modal';
import { TextInput } from 'ui/text-input';

import styles from './index.module.scss';

const DEFAULT_PREVIOUS_PATH = LpPlatformRoutes.Home;

export const ContactUsPage = () => {
  const { t } = useTranslation();
  const { user } = useAppContext();
  const [errors, setErrors] = useState<string | null>(null);
  const [fileErrors, setFileErrors] = useState<string | null>(null);
  const [uploadedFileList, setUploadedFileList] = useState<IFile[]>([]);
  const [getUrl] = useGetUploadUrlLazyQuery();
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);

  const handleCloseModal = () => {
    setOpenSuccessModal(false);
    router.navigate(DEFAULT_PREVIOUS_PATH);
  };

  const totalUploadedSize = uploadedFileList.reduce(
    (acc, curr) => (acc = acc + curr.size),
    0,
  );

  const formik = useFormik({
    initialValues: {
      copy: false,
      email: user?.email ?? '',
      firstName: '',
      id: user?.userId ?? '',
      lastName: '',
      lpName: '',
      mailSubject: '',
      message: '',
      page: DEFAULT_PREVIOUS_PATH,
      files: [],
    },
    validateOnMount: true,
    validationSchema: yup.object().shape({
      lpName: simpleSelect(t),
      firstName: name(t),
      lastName: name(t),
      mailSubject: name(t),
      message: message(t),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const fileValues = { ...values, files: uploadedFileList };
        await askForContactRequest(fileValues);
        setOpenSuccessModal(true);
      } catch (error) {
        setErrors(getAuthErrorContextFromAuthProviderError(error).context);
      }
      setSubmitting(false);
      formik.resetForm();
      setUploadedFileList([]);
      setFileErrors(null);
    },
  });

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFileErrors(null);
    const files = e.currentTarget.files;

    if (files) {
      const fileArray = Array.from(files);
      const totalFilesSize = fileArray.reduce(
        (acc, curr) => (acc = acc + curr.size),
        0,
      );

      if (totalFilesSize + totalUploadedSize > MAX_ATTACHMENTS_FILE_SIZE) {
        setFileErrors('Size of uploaded files must not exceeds 8 Mb');
      } else {
        fileArray.forEach((file) => {
          const newFile = {
            name: file.name,
            type: file.type,
            size: file.size,
            filePath: generateUniqueFilePath(files[0].name),
          };

          getUrl({
            variables: {
              input: {
                fileName: newFile.filePath,
              },
            },
          })
            .then(({ data }) => {
              if (data && data.uploadUrlResponse.url) {
                return uploadObjectAsync(file, data.uploadUrlResponse.url);
              }
            })
            .then((response) => {
              if (response && response.status === 200) {
                setUploadedFileList([...uploadedFileList, newFile]);
              }
            });

          formik.setFieldValue('files', newFile);
        });
      }
    }
  };

  const removeUploadedFile = (file: IFile) => {
    setUploadedFileList((prevValues) =>
      prevValues.filter((uploadedFile) => uploadedFile !== file),
    );

    if (totalUploadedSize < MAX_ATTACHMENTS_FILE_SIZE) {
      setFileErrors(null);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  return (
    <ConnectedPage title={t('pages.contactRequest.title')}>
      <Stack className={styles.backLinkContainer}>
        <IconButton
          color="primary"
          onClick={() => router.navigate(DEFAULT_PREVIOUS_PATH)}
        >
          <ArrowBack sx={{ height: '60px', width: '60px' }} />
        </IconButton>
      </Stack>
      <Stack className={styles.container}>
        {formik.isSubmitting ? (
          <Stack className={styles.loaderOverlay}>
            <Loader />
          </Stack>
        ) : null}
        <form className={styles.form} onSubmit={(e) => handleSubmit(e)}>
          <Stack spacing={2}>
            <Stack direction="row" spacing={2}>
              <TextInput
                id="firstName"
                name="firstName"
                className={styles.smallInput}
                label={t('pages.contactRequest.form.label.firstName')}
                type="text"
                value={formik.values.firstName}
                disabled={formik.isSubmitting}
                onChange={(e) => {
                  formik.setTouched({
                    ...formik.touched,
                    firstName: undefined,
                  });
                  formik.handleChange(e);
                }}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formikErrorMessage(formik, 'firstname')}
                data-test-id="mail-firstName-input"
              />
              <TextInput
                id="lastName"
                name="lastName"
                className={styles.smallInput}
                label={t('pages.contactRequest.form.label.lastName')}
                type="text"
                value={formik.values.lastName}
                onChange={(e) => {
                  formik.setTouched({ ...formik.touched, lastName: undefined });
                  formik.handleChange(e);
                }}
                error={
                  formik.touched.mailSubject && Boolean(formik.errors.lastName)
                }
                helperText={formikErrorMessage(formik, 'login')}
                data-test-id="mail-object-input"
              />
            </Stack>
            <TextInput
              id="email"
              name="email"
              label={t('pages.contactRequest.form.label.email')}
              type="email"
              value={formik.values.email}
              disabled={formik.isSubmitting}
              onChange={(e) => {
                formik.setTouched({ ...formik.touched, email: undefined });
                formik.handleChange(e);
              }}
              error={formik.touched.mailSubject && Boolean(formik.errors.email)}
              data-test-id="mail-email-input"
            />
            <DropDown
              id="lpName"
              name="lpName"
              placeholder={t('pages.contactRequest.form.label.lpName')}
              label={t('pages.contactRequest.form.label.lpName')}
              value={formik.values.lpName}
              options={user?.lps?.map((lp) => lp.name) ?? []}
              onChange={(e) => {
                formik.setTouched({ ...formik.touched, lpName: undefined });
                formik.handleChange(e);
              }}
              disabled={formik.isSubmitting}
              error={formik.touched.lpName && Boolean(formik.errors.lpName)}
              dataTestId="mail-lpName-input"
            />
            <TextInput
              id="mailSubject"
              name="mailSubject"
              label={t('pages.contactRequest.form.label.mailSubject')}
              type="text"
              disabled={formik.isSubmitting}
              value={formik.values.mailSubject}
              onChange={(e) => {
                formik.setTouched({
                  ...formik.touched,
                  mailSubject: undefined,
                });
                formik.handleChange(e);
              }}
              error={
                formik.touched.mailSubject && Boolean(formik.errors.mailSubject)
              }
              data-test-id="mail-mailSubject-input"
            />
            <TextInput
              id="message"
              name="message"
              multiline
              rows={10}
              label={t('pages.contactRequest.form.label.message')}
              type="text"
              disabled={formik.isSubmitting}
              value={formik.values.message}
              onChange={(e) => {
                formik.setTouched({ ...formik.touched, message: undefined });
                formik.handleChange(e);
              }}
              error={formik.touched.message && Boolean(formik.errors.message)}
              data-test-id="mail-message-input"
            />
            <FileInput onChange={handleFileChange} maximumFileSize="8 MB" />
            <LinearProgress
              className={styles.progressBar}
              variant="determinate"
              value={(totalUploadedSize * 100) / MAX_ATTACHMENTS_FILE_SIZE}
            />
            {fileErrors && <p className={styles.formError}>{fileErrors}</p>}
            {uploadedFileList && (
              <Stack className={styles.uploadedFileContainer}>
                {uploadedFileList.map((file) => (
                  <UploadedFile
                    file={file}
                    handleRemoveFile={removeUploadedFile}
                    key={file.filePath}
                  />
                ))}
              </Stack>
            )}
            <CheckBox
              label={t('pages.contactRequest.form.label.copy')}
              field="copy"
              checked={formik.values.copy}
              onChange={(e) => {
                formik.setTouched({ ...formik.touched, copy: undefined });
                formik.handleChange(e);
              }}
            />
            {errors && <p className={styles.formError}>{errors}</p>}
            <Button
              className={styles.submitButton}
              data-test-id="submit-button"
              variant="contained"
              type="submit"
            >
              {t('pages.contactRequest.form.label.submit')}
            </Button>
          </Stack>
        </form>
      </Stack>
      <BasicModal
        open={openSuccessModal}
        handleClose={handleCloseModal}
        text={{
          main: t('pages.contactRequest.form.successMessage.main'),
          secondary: t('pages.contactRequest.form.successMessage.secondary'),
        }}
      />
    </ConnectedPage>
  );
};
