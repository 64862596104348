import { debounce, Divider, Stack } from '@mui/material';
import { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'translations/hook';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import LpInformationCard from 'business/lp-platform/home/components/lp-information-card';
import {
  FundManagerDashboardLpQuery,
  useUpdateLpCommentMutation,
  useWebDocumentDownloadUrlQuery,
} from 'generated/graphql';
import { InfoCard } from 'ui/info-card';
import { TextInput } from 'ui/text-input';

interface Props {
  infos: FundManagerDashboardLpQuery['lpDashboardInformation']['lpInformation'];
  lpName: string;
  logoDocumentId?: string | null;
  lpComment?: string | null;
}

const GeneralInfosCard = ({
  infos,
  lpName,
  logoDocumentId,
  lpComment,
}: Props) => {
  const { t } = useTranslation();

  const { data } = useWebDocumentDownloadUrlQuery({
    variables: {
      input: {
        ids: [logoDocumentId ?? ''],
      },
    },
    fetchPolicy: 'network-only',
    skip: !logoDocumentId,
  });
  const [comment, setComment] = useState(lpComment ?? '');
  const [updateLpCommentMutation] = useUpdateLpCommentMutation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateLpComment = useCallback(
    debounce((newComment: string) => {
      updateLpCommentMutation({
        variables: {
          input: {
            lpId: infos.id,
            comment: newComment,
          },
        },
      });
    }, 500),
    [infos.id, updateLpCommentMutation],
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newComment = e.target.value;
    setComment(newComment);
    debouncedUpdateLpComment(newComment);
  };
  return (
    <InfoCard dataTestId="lp-general-card">
      <InfoCard.Title
        path={FundManagerRoutes.LpIdEditProfile.replace(
          ':lpId',
          infos.id ?? '',
        )}
      >
        {t('pages.dashboard.cards.generalInfos.title')}
      </InfoCard.Title>
      <LpInformationCard
        lpName={lpName}
        address={infos.address}
        logoPath={data?.documentDownloadUrl.url}
      >
        <>
          <Divider />
          <Stack flexGrow={1}>
            <TextInput
              multiline
              rows={5}
              label={t('pages.dashboard.cards.generalInfos.comment.label')}
              type="text"
              placeholder={t(
                'pages.dashboard.cards.generalInfos.comment.placeholder',
              )}
              value={comment}
              onChange={handleChange}
            />
          </Stack>
        </>
      </LpInformationCard>
    </InfoCard>
  );
};

export default GeneralInfosCard;
